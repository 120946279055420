<template>
  <Modal
    :activeModal="activeModal"
    :widthModal="450"
    @closeModal="closeModal">
   <template v-slot:btnActivator>
      <v-btn
        tile
        v-if="permiteAcao($route, 'add')" 
        :color="variables.colorPrimary"
        dark
        class="mb-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        :loading="loadingBtnCadastro">Novo cadastro</v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          {{ isEdit ? 'Editar Chips' : 'Cadastrar Chips' }}
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      
      <v-form>
        <!-- <v-card-title class="modal-cadastro-titulo">
          
        </v-card-title> -->

          <v-card-text class="modal-cadastro-form">
          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-text-field :error-messages="iccidErrors" v-model="item.iccid" label="ICCID"  @input="$v.item.iccid.$touch()" @blur="$v.item.iccid.$touch()" counter="20" v-mask="'####################'" @keypress="disableSubmit"/>
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput"><v-text-field :error-messages="imsiErrors" v-model="item.imsi" label="IMSI" @input="$v.item.imsi.$touch()" @blur="$v.item.imsi.$touch()" counter="15"  v-mask="'###############'" @keypress="disableSubmit"/></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput"><v-text-field :error-messages="phoneNumberErrors" v-model="item.phoneNumber" label="Número do chip:" @input="$v.item.phoneNumber.$touch()" @blur="$v.item.phoneNumber.$touch()"  v-mask="['(##) #####-####' ]" @keypress="disableSubmit"/></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-select
                v-model="item.chipCarrierId"
                :items="listCarriers"
                label="Operadora"></v-select>
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput">
              <v-select
                v-model="item.chipContractId"
                :items="listContracts"
                label="Contrato"></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loading">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength} from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import { formatPhone } from '@/core/service/utils'
import variables from '@/assets/styles/helpers/_variables.scss'

import validacao from './validacao'

export default {
  name: 'BrModalCadastroOSC',
  mixins: [validationMixin],
  directives: {mask},
  components: {
    Modal: () => import('@/views/components/modal')
  },
  data: () => ({
    activeModal: false,
    loading: false
  }),
  computed: {
    ...mapGetters('chips', ['item']),
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('chips', ['listCarriers', 'listContracts']),
    ...validacao,
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    }
  },
  mounted () {
    Events.$on('cadastro::openModalCadastro', () => {
      this.openModal()
    })
  },
  methods: {
    ...mapActions('chips', ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'getCarriers', 'getContracts']),
    openModal () {
      this.activeModal = true
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.$v.item.$reset()
    },

    submit () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        return false 
      }

      this.loading = true
      const dados = {
        iccid: this.item.iccid,
        imsi: this.item.imsi,
        phoneNumber: formatPhone(this.item.phoneNumber),
        chipCarrierId: this.item.chipCarrierId,
        chipContractId: this.item.chipContractId
      }

      if (this.item.id) {
        dados.id = this.item.id

        this.editarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Edição realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.msg || 'Edição não realizado!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      } else {
        this.cadastrarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error || 'Cadastro não realizado!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      }

    },

    disableSubmit (e) {
      if(e.which == 13) {
        //cancela a ação padrão
        event.preventDefault();
      }
    }
  },

  validations () {
    return  {
      item: {
        iccid: { required, maxLength: maxLength(20) },
        imsi: { required, maxLength: maxLength(15) },
        phoneNumber: { required, minLength: minLength(15) },
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../../assets/styles/components/formularios';
  @import '../../../../../assets/styles/components/modal-cadastro';
</style>