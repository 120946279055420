export default {
  // validações dados ICCID
  iccidErrors () {
    const errors = []
    if (!this.$v.item.iccid.$dirty) return errors
    !this.$v.item.iccid.required && errors.push('Código do chip obrigatório.')
    !this.$v.item.iccid.maxLength && errors.push('Quantidade de caracteres excedido.')
    return errors
  },

  imsiErrors () {
    const errors = []
    if (!this.$v.item.imsi.$dirty) return errors
    !this.$v.item.imsi.required && errors.push('Código do chip obrigatório.')
    !this.$v.item.imsi.maxLength && errors.push('Quantidade de caracteres excedido.')
    return errors
  },

  // validações dados phoneNumber
  phoneNumberErrors () {
    const errors = []
    if (!this.$v.item.phoneNumber.$dirty) return errors
    !this.$v.item.phoneNumber.required && errors.push('Número do chip obrigatório.')
    !this.$v.item.phoneNumber.minLength && errors.push('Número do chip incorreto.')
    return errors
  },
}
